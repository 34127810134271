import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { Input, message } from "antd";
import { register } from "../../api/auth";
import "../../styles/register.css";
import Prout from "../common/Prout";
import RainbowButton from "../common/RainbowButton";
const Registration = () => {
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const mobile_prefix = import.meta.env.VITE_MOBILE_NUMBER || 65;

  function isValidMobileNumber(phoneNumber) {
    //const regex = /^()\d{8||10}$/;
    const regex = /^(\d{8}|\d{10})$/;
    return regex.test(phoneNumber);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidMobileNumber(mobileNumber)) {
      message.error("Invalid mobile number");
      return;
    }
    if (password.length < 5) {
      message.error("Password should have at least 5 characters");
      return;
    }
    if (password !== confirmPassword) {
      message.error("Password doesn't match");
      return;
    }
    setIsLoading(true);
    try {
      const processedMobile = mobile_prefix + mobileNumber;
      const token = await register(email, username, password, processedMobile);
      localStorage.setItem("authToken", token);
      setErrorMessage("");
      setSuccessMessage("Registration success! Check email for verification!");
      setEmail("");
      setUsername("");
      setPassword("");
      setMobileNumber("");
      setConfirmPassword("");
      message.success("Registered successfully");
      navigate("/otp");
      // Redirect the user to the dashboard or another protected route
    } catch (error) {
      const STATUS = error.response.status;
      if (STATUS === 429) {
        setErrorMessage("Slow down! You're making too many requests");
        return;
      }
      setErrorMessage("Make sure all fields are valid");
    } finally {
      setIsLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  return (
    <Container className="mt-5" fluid>
      <Prout />
      <Row className="justify-content-center mx-5 mx-md-0">
        <Col md={6}>
          {/* <h2 className="text-right mb-4">Create an account</h2> */}
          <Form onSubmit={handleSubmit}>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {successMessage && <Alert severity="success">{successMessage}</Alert>}

            <Form.Group controlId="username" className="form-group-spacing">
              <Form.Label>Username</Form.Label>
              <TextField
                placeholder="At least 4 characters"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="standard"
                inputProps={{ className: "custom-input" }}
                InputLabelProps={{ className: "small-placeholder" }}
              />
            </Form.Group>

            <Form.Group controlId="password" className="form-group-spacing">
              <Form.Label>Password</Form.Label>
              <TextField
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                name="password"
                variant="standard"
                inputProps={{ className: "custom-input" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        size="small"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Form.Group>

            <Form.Group controlId="password" className="form-group-spacing">
              <Form.Label>Confirm password</Form.Label>
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                name="confirmPassword"
                variant="standard"
                inputProps={{ className: "custom-input" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleConfirmPasswordVisibility}
                        size="small"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Form.Group>

            <Form.Group controlId="email" className="form-group-spacing">
              <Form.Label>Email Address</Form.Label>
              <TextField
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                variant="standard"
                inputProps={{ className: "custom-input" }}
                InputLabelProps={{ className: "small-placeholder" }}
              />
            </Form.Group>

            <Form.Group controlId="mobileNumber" className="form-group-spacing">
              <Form.Label>Singapore Mobile Number</Form.Label>

              <Input
                addonBefore="+65"
                placeholder="xxxxxxxx"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                fullWidth
                variant="standard"
                inputProps={{ className: "custom-input" }}
                InputLabelProps={{ className: "small-placeholder" }}
              />
            </Form.Group>

            <Form.Group controlId="termsCheckbox" className="form-group-spacing">
              <Form.Check type="checkbox">
                <Form.Check.Input
                  value={isAgree}
                  onChange={() => setIsAgree((prev) => !prev)}
                />
                <Form.Check.Label>
                  I agree to the{" "}
                  <a href="/register-terms" className="no-underline">
                    <strong>Terms of Services</strong>
                  </a>{" "}
                  and{" "}
                  <a href="/register-privacy" className="no-underline">
                    <strong>Privacy Policy</strong>
                  </a>
                </Form.Check.Label>
              </Form.Check>
              <Form.Check type="checkbox">
                <Form.Check.Input
                  value={isAdult}
                  onChange={() => setIsAdult((prev) => !prev)}
                />
                <Form.Check.Label>I'm above 18 years old</Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Typography mt={2} variant="subtitle2">
              Already have an account? Login <Link to="/login">here.</Link>
            </Typography>

            {isLoading ? (
              <div className="text-center">
                <CircularProgress style={{ margin: "auto" }} />
              </div>
            ) : (
              // <Button
              //   variant="secondary"
              //   type="submit"
              //   className="custom-rainbow-button mt-3"
              //   disabled={!isAdult || !isAgree}
              // >
              //   Sign up
              // </Button>
              <RainbowButton disabled={!isAdult || !isAgree} type="submit">
                Enter
              </RainbowButton>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Registration;
